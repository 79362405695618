import { typeOfId, propertyType } from '@lib/config'
import { isWithinMonth } from '@lib/datetime'
import { isObject, pick, isArray, isString } from 'lodash-es'

export function getHouseData(data) {
  const tmp = {}
  
  if(!isObject(data)) {
    return tmp
  }

  Object.assign(tmp, pick(data, [
    'bedrooms', 'bathrooms', 'carspaces', 'landarea', 'floorarea', 
    'id', 'slug', 'lng', 'lat', 'code', 'tags', 'title', 'agents', 'country',
    'description', 'images', 'street', 'avm', 'show_avm', 'status', 'rental', 'publisher',
    'special', 'animation', 'price', 'uid', 'external_id', 'office_external_id',
    'is_show_calculation', 'is_homely', 'hide_avm_trending', 'area_detail',
  ]))
  
  /** 
   * type_id要放在最前面
   * 后端解释说：如果是估价、售出 type_id一定会有值
   * 如果没有值，就取 type.id
   */
  tmp.typeId = data.type_id || data.type?.id

  /**
   * 如果typeId === -100，且有售出信息，还要判断是否是最近一年售出的
   * 如果是最近一年售出的，则标记为售出，typeId = -99
   */
  if(
    tmp.typeId === propertyType.ESTIMATE 
    && data?.sold_info?.sold_date 
    && isWithinMonth(data.sold_info.sold_date)
  ) {
    tmp.typeId = propertyType.SOLD
  }

  tmp.type = typeOfId[tmp.typeId]
  tmp.propertyId = data.property_id
  tmp.houseId = [
    propertyType.SOLD, propertyType.ESTIMATE
  ].indexOf(tmp.typeId) > -1 ? null : tmp.id
  
  tmp.useNote = data.user_note
  tmp.h1 = data.teaser
  tmp.cover = data.pic
  tmp.imageTagTidy = data.image_tag_tidy
  tmp.floorImages = data.floor_plan_images || []
  tmp.videoUrl = data.video?.overseas || ''
  tmp.newHouse = data.new_house
  tmp.auction = data.auction_time?.start 
    ? { ...data.auction_time, time_start: parseFloat(data.auction_time.start) } 
    : null

  // 澳洲
  tmp.stateId = isString(data.state_id) ? data.state_id.toLowerCase() : data.state_id
  tmp.auPlanZone = data.au_plan_zone
  tmp.externalUrl = data.external_url
  
  tmp.regionName = data.region_name
  tmp.districtName = data.district_name
  tmp.shareLink = data.share_link
  tmp.isShowCase = data.is_showcase
  tmp.isFeature = data.is_feature

  tmp.priceGuideUrl = data.price_guide_url
  tmp.favoriteCount = data.favourite_count
  tmp.openHomes = data.open_homes
  tmp.openHomeLabel = data?.open_homes?.[0]?.label || ''
  tmp.auctionAddress = data.auction_address
  tmp.category = data.category?.id ? data.category : null
  tmp.priceMethod = data.price_method?.id ? data.price_method : null
  tmp.categoryName = data.category ? data.category.ch : ''
  tmp.regionName = data.region_name || ''
  tmp.regionId = data.region_id
  tmp.districtName = data.district_name || ''
  tmp.districtId = data.district_id
  tmp.suburbName = data.suburb_name || ''
  tmp.suburbId = data.suburb_id
  tmp.address = data.display_address || (isArray(data.address) ? data.address.join(', ') : data.address)
  tmp.publishAddress = data.publish_address // 是否隐藏地址
  tmp.newListing = data.new_listing
  tmp.newHomes = data.new_homes
  tmp.listAtLabel = data.list_at_label
  tmp.threeDView = data.three_d_view
  tmp.soldDate = data.sold_date
  tmp.soldInfo = data.sold_info
  tmp.priceBold = data.price_bold
  tmp.priceSearch = data.price_search
  tmp.priceLabel = `${data.price_bold || ''}${data.price_light || ''}`
  tmp.userNote = data.user_note || ''
  tmp.publicRecords = data.public_records
  tmp.unitaryPlanZone = data.unitary_plan_zone
  tmp.loanParams = data.loan_params
  tmp.updateAt = data.update_at
  tmp.office = isArray(data.offices) && data.offices.length > 0
    ? {
      ...data.offices[0], 
      officeColor: data.office_color, 
      fontColor: data.font_color 
    }
    : null

  if(!tmp.lng) {
    tmp.lng = data.longitude
    tmp.lat = data.latitude
  }

  if(tmp.typeId === propertyType.ESTIMATE || tmp.typeId === propertyType.SOLD) {
    tmp.h1 = tmp.address
    // tmp.address = null
  }

  // 是否为个人房东
  tmp.isPublisher = isArray(data?.agents) && data?.agents?.length === 0 && !!data?.publisher

  return tmp
}
